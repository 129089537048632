import React from 'react';
import { Typography, Container, Link } from '@mui/material';
import { Grid2 } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledFooter = styled('footer')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: theme.spacing(6, 0),
    marginTop: 'auto',
}));

const StyledLink = styled(Link)(({ theme }) => ({
    color: '#FFFFFF',
    '&:hover': {
        color: theme.palette.secondary.light,
    },
}));

const StyledTypography = styled(Typography)({
    color: '#FFFFFF',
});

function Footer() {
    return (
        <StyledFooter>
            <Container maxWidth="lg">
                <Grid2 container spacing={3} justifyContent="space-between">
                    <Grid2 item xs={12} sm={4}>
                        <StyledTypography variant="h6" gutterBottom>
                            Borrowed
                        </StyledTypography>
                        <StyledTypography variant="body2">
                            Find what you need, lend what you don't use.
                        </StyledTypography>
                    </Grid2>
                    <Grid2 item xs={12} sm={4}>
                        <StyledTypography variant="h6" gutterBottom>
                            Quick Links
                        </StyledTypography>
                        <StyledLink component={RouterLink} to="/about" variant="body2" display="block">
                            About Us
                        </StyledLink>
                        <StyledLink component={RouterLink} to="/privacy" variant="body2" display="block">
                            Privacy Policy
                        </StyledLink>
                        <StyledLink component={RouterLink} to="/terms" variant="body2" display="block">
                            Terms of Service
                        </StyledLink>
                    </Grid2>
                    <Grid2 item xs={12} sm={4}>
                        <StyledTypography variant="h6" gutterBottom>
                            Contact Us
                        </StyledTypography>
                        <StyledTypography variant="body2">
                            Email: support@borrowed.com
                        </StyledTypography>
                        <StyledTypography variant="body2">
                            Phone: (123) 456-7890
                        </StyledTypography>
                    </Grid2>
                </Grid2>
                <StyledTypography variant="body2" align="center" style={{ marginTop: '2rem' }}>
                    © {new Date().getFullYear()} Borrowed. All rights reserved.
                </StyledTypography>
            </Container>
        </StyledFooter>
    );
}

export default Footer;