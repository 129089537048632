import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { joinWaitlist } from '../services/api';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Facebook, Instagram, X } from '@mui/icons-material';

const greenColor = "#4CAF50"; // The green color to match the logo

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
    color: greenColor,
}));

const StyledForm = styled('form')(({ theme }) => ({
    width: '100%',
    maxWidth: 400,
    marginTop: theme.spacing(3),
}));

const LogoImage = styled('img')(({ theme }) => ({
    width: 350,
    height: 350,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3), // Add this line for padding
    boxSizing: 'border-box',   // Ensure padding is included in the width/height calculation
}));

const SocialLinks = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    '& a': {
        color: greenColor,
        margin: theme.spacing(1),
    },
}));

function WaitlistForm() {
    const [email, setEmail] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [loading, setLoading] = useState(false);
    const [referralCode, setReferralCode] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        setLoading(true);

        try {
            const recaptchaToken = await executeRecaptcha('join_waitlist');
            await joinWaitlist(email, referralCode, recaptchaToken);
            setSnackbar({ open: true, message: 'Thank you for joining our waitlist!', severity: 'success' });
            setEmail('');
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setSnackbar({ open: true, message: 'This email is already on the waitlist.', severity: 'warning' });
            } else {
                setSnackbar({ open: true, message: 'An error occurred. Please try again later.', severity: 'error' });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledContainer maxWidth="sm">
            {/* Use the correct path for the logo */}
            <LogoImage src={`${process.env.PUBLIC_URL}/borrowed_logo.png`} alt="Borrowed Logo" />
            {/* Updated bold and styled text */}
            <Typography variant="h5" component="p" gutterBottom>
                <strong>Psst…</strong> something groundbreaking is on the horizon, and it’s set to change how we think about ownership and sustainability.
            </Typography>

            <StyledForm onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    label="Email Address"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    required
                    type="email"
                />
                <Box mt={2}>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ backgroundColor: greenColor, color: '#fff' }}
                        size="large"
                        fullWidth
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Join the Movement'}
                    </Button>
                </Box>
            </StyledForm>

            <SocialLinks>
                <a href="https://instagram.com/getborrowed" target="_blank" rel="noopener noreferrer">
                    <Instagram />
                </a>
                <a href="https://facebook.com/borrowed" target="_blank" rel="noopener noreferrer">
                    <Facebook />
                </a>
                <a href="https://x.com/getborrowed" target="_blank" rel="noopener noreferrer">
                    <X />
                </a>
            </SocialLinks>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </StyledContainer>
    );
}

function Waitlist() {
    const GOOGLE_CLOUD_RECAPTCHA_SITE_KEY = '6Lcqk18qAAAAAHYimYNVW6XPFRGEHKrsISiIlB5y';

    return (
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CLOUD_RECAPTCHA_SITE_KEY}>
            <WaitlistForm />
        </GoogleReCaptchaProvider>
    );
}

export default Waitlist;
