import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4CAF50',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#f5f5f5',
        },
    },
    typography: {
        fontFamily: 'Filson Soft, Arial, sans-serif', // Updated to use Filson Soft
        fontWeightRegular: 550, // Increased from 400 to 500 for a bolder look
        fontWeightMedium: 650,
        fontWeightBold: 750,
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Filson Soft';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: url('/fonts/Filson_Soft_Regular.otf') format('opentype');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
            `,
        },
    },

});

export default theme;
