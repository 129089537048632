// src/context/AuthContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import { getCurrentUser } from '../services/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true); // New loading state

    useEffect(() => {
        checkLoginStatus();
    }, []);

    const checkLoginStatus = async () => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            try {
                const user = await getCurrentUser();
                setIsLoggedIn(true);
                setCurrentUser(user.data);
                console.log('User is logged in:', user.data);
            } catch (error) {
                console.error('Error fetching current user', error);
                setIsLoggedIn(false);
                setCurrentUser(null);
            }
        } else {
            console.log('No access token found, user is not logged in');
            setIsLoggedIn(false);
            setCurrentUser(null);
        }
        setLoading(false); // Set loading to false after fetching
    };

    const logout = () => {
        setIsLoggedIn(false);
        setCurrentUser(null);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, currentUser, logout, checkLoginStatus, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
