import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Box, List, ListItem, ListItemText, Avatar,
    IconButton, Modal, TextField, Button, Radio, RadioGroup, FormControlLabel,
    Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getUserTransactions } from '../services/api';
import { useAuth } from '../context/AuthContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
        borderBottom: 'none',
    },
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
}));

const ListingImage = styled('img')({
    width: '120px',
    height: '120px',
    objectFit: 'cover',
    borderRadius: '8px',
    marginRight: '16px',
});

const BorrowerAvatar = styled(Avatar)({
    width: '32px',
    height: '32px',
    fontSize: '14px',
    marginRight: '8px',
});

const FilterModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const FilterPaper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    width: '300px',
}));
function MyLendingBookings() {
    const [bookings, setBookings] = useState([]);
    const [sortBy, setSortBy] = useState('rentalPeriodStart');
    const [tempSortBy, setTempSortBy] = useState('rentalPeriodStart');
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [tempSearchTerm, setTempSearchTerm] = useState('');
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    useEffect(() => {
        fetchBookings();
    }, []);

    const fetchBookings = async () => {
        try {
            const response = await getUserTransactions();
            // Filter bookings where current user is the lender
            const lenderBookings = response.data.filter(booking => booking.lender_id === currentUser.id);
            setBookings(lenderBookings || []);
        } catch (error) {
            console.error('Error fetching lending bookings:', error);
        }
    };

    const getBorrowerInitials = (name) => {
        if (!name) return '';
        const nameParts = name.split(' ');
        return `${nameParts[0]} ${nameParts[1] ? nameParts[1][0] + '.' : ''}`.trim();
    };

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'pending':
                return 'warning';
            case 'accepted':
            case 'paid':
            case 'in_progress':
                return 'success';
            case 'canceled':
            case 'declined':
                return 'error';
            default:
                return 'default';
        }
    };


    const handleOpenFilterModal = () => {
        setTempSortBy(sortBy);
        setTempSearchTerm(searchTerm);
        setFilterModalOpen(true);
    };

    const handleCloseFilterModal = () => {
        setFilterModalOpen(false);
    };

    const handleUpdateFilter = () => {
        setSortBy(tempSortBy);
        setSearchTerm(tempSearchTerm);
        setFilterModalOpen(false);
    };


    const sortedAndFilteredBookings = bookings
        .filter(booking =>
            (booking.listing_title || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (booking.borrower_name || '').toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (sortBy === 'lastActivity') {
                return new Date(b.updated_at || 0) - new Date(a.updated_at || 0);
            } else if (sortBy === 'rentalPeriodStart') {
                return new Date(a.start_date || 0) - new Date(b.start_date || 0);
            } else if (sortBy === 'rentalPeriodEnd') {
                return new Date(a.end_date || 0) - new Date(b.end_date || 0);
            }
            return 0;
        });

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Box display="flex" alignItems="center" mb={2}>
                <IconButton onClick={() => navigate('/lending-bookings')} edge="start" sx={{ mr: 2 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" component="h1" flexGrow={1}>
                    My Lending Bookings
                </Typography>
                <IconButton onClick={handleOpenFilterModal}>
                    <FilterListIcon />
                </IconButton>
            </Box>

            <List>
                {sortedAndFilteredBookings.map((booking) => (
                    <StyledListItem
                        key={booking.id}
                        component={RouterLink}
                        to={`/lending-bookings/${booking.id}`}
                    >
                        <ListingImage src={booking.listing_image} alt={booking.listing_title} />
                        <Box sx={{ flexGrow: 1 }}>
                            <Box display="flex" alignItems="center" mb={1}>
                                <BorrowerAvatar src={booking.borrower_profile_picture} alt={booking.borrower_name}>
                                    {booking.borrower_name ? booking.borrower_name[0].toUpperCase() : 'U'}
                                </BorrowerAvatar>
                                <Typography variant="subtitle1" component="span">
                                    {getBorrowerInitials(booking.borrower_name)}
                                </Typography>
                                <Chip
                                    label={booking.status}
                                    color={getStatusColor(booking.status)}
                                    size="small"
                                    sx={{ ml: 'auto' }}
                                />
                            </Box>
                            <ListItemText
                                primary={booking.listing_title || 'Unnamed Item'}
                                secondary={
                                    <>
                                        <Typography component="span" variant="body2" color="text.primary">
                                            {booking.status === 'pending' ? 'New booking request' : `Booking ${booking.status.toLowerCase()}`}
                                        </Typography>
                                        <br />
                                        {`Rental period: ${new Date(booking.start_date).toLocaleDateString()} - ${new Date(booking.end_date).toLocaleDateString()}`}
                                        <br />
                                        {`Last activity: ${new Date(booking.updated_at).toLocaleDateString()}`}
                                        <br />
                                        {`Amount: $${booking.transaction_amount.toFixed(2)}`}
                                    </>
                                }
                            />
                        </Box>
                        <ChevronRightIcon color="action" sx={{ alignSelf: 'center' }} />
                    </StyledListItem>
                ))}
            </List>
            {sortedAndFilteredBookings.length === 0 && <Typography>No lending bookings found.</Typography>}

            <FilterModal
                open={filterModalOpen}
                onClose={handleCloseFilterModal}
            >
                <FilterPaper>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Filter
                    </Typography>
                    <TextField
                        fullWidth
                        label="Search by item or borrower name"
                        value={tempSearchTerm}
                        onChange={(e) => setTempSearchTerm(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Typography variant="subtitle1" gutterBottom>
                        What do you want to sort by?
                    </Typography>
                    <RadioGroup
                        value={tempSortBy}
                        onChange={(e) => setTempSortBy(e.target.value)}
                    >
                        <FormControlLabel value="lastActivity" control={<Radio />} label="Last activity" />
                        <FormControlLabel value="rentalPeriodStart" control={<Radio />} label="Rental period start" />
                        <FormControlLabel value="rentalPeriodEnd" control={<Radio />} label="Rental period end" />
                    </RadioGroup>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleUpdateFilter}
                        sx={{ mt: 2 }}
                    >
                        Update
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleCloseFilterModal}
                        sx={{ mt: 1 }}
                    >
                        Cancel
                    </Button>
                </FilterPaper>
            </FilterModal>
        </Container>
    );
}

export default MyLendingBookings;