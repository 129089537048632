import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
}));

function PrivacyPolicy() {
    return (
        <Container maxWidth="md" style={{ marginTop: '50px' }}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Privacy Policy
            </Typography>
            <StyledPaper>
                <Typography variant="body1" paragraph>
                    At Borrowed, we take your privacy seriously. This policy describes how we collect, use, and protect your personal information.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Information We Collect
                </Typography>
                <Typography variant="body1" paragraph>
                    We collect information you provide directly to us, such as your name, email address, and location. We also collect data about your usage of our platform.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    How We Use Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We use your information to provide and improve our services, communicate with you, and ensure the security of our platform.
                </Typography>
                {/* Add more sections as needed */}
            </StyledPaper>
        </Container>
    );
}

export default PrivacyPolicy;