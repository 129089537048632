import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from './context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Register from './pages/Register';
import Listings from './pages/Listings';
import ListingDetail from './pages/ListingDetail';
import TransactionDetail from './pages/BorrowingBookingDetails';
import BookingsPage from './pages/BorrowingBookings';
import LendingBookings from './pages/LendingBookings';
import LendingBookingDetail from './pages/LendingBookingDetails';
import Profile from './pages/Profile';
import AboutUs from './pages/AboutUs';
import MyBookings from './pages/MyBookings';
import MyLendingBookings from './pages/MyLendingBookings';
import MyListings from './pages/MyListings';
import MyListing from './pages/MyListing';
import CreateListing from './pages/CreateListing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Inbox from './pages/Inbox';
import Waitlist from './pages/Waitlist';
import theme from './theme';
import { ModeProvider } from './context/ModeContext';

// ... other imports and code


// Set this to false to enable the waitlist page
const IS_SITE_LIVE = false;

function App() {
  return (
    <AuthProvider>
      <ModeProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {IS_SITE_LIVE && <Header />}
            <main style={{ flexGrow: 1 }}>
              <Routes>
                {IS_SITE_LIVE ? (
                  <>
                    <Route path="/" element={<Listings />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/create-listing" element={<CreateListing />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/listings" element={<Listings />} />
                    <Route path="/listings/:id" element={<ListingDetail />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/inbox" element={<Inbox />} />
                    <Route path="/transactions/:id" element={<TransactionDetail />} />
                    <Route path="/waitlist" element={<Waitlist />} />
                    <Route path="/bookings" element={<BookingsPage />} />
                    <Route path="/my-bookings" element={<MyBookings />} />
                    <Route path="/my-lending-bookings" element={<MyLendingBookings />} />
                    <Route path="/my-listings" element={<MyListings />} />
                    <Route path="/my-listings/:id" element={<MyListing />} />
                    <Route path="/lending-bookings" element={<LendingBookings />} />
                    <Route path="/lending-bookings/:id" element={<LendingBookingDetail />} />

                  </>
                ) : (
                  <>
                    <Route path="/" element={<Waitlist />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </>
                )}
              </Routes>
            </main>
            {IS_SITE_LIVE && <Footer />}
          </div>
        </ThemeProvider>
      </ModeProvider>
    </AuthProvider>
  );
}

export default App;