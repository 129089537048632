import React from 'react';
import { Typography, Button, Container, Grid, Card, CardContent, CardMedia, Box, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';

const HeroSection = styled('div')({
    backgroundImage: 'url(/hero-image.jpg)', // Replace with your actual hero image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
});

const HeroContent = styled(Box)({
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    padding: '2rem',
    borderRadius: '8px',
    textAlign: 'center',
});

const SectionTitle = styled(Typography)({
    textAlign: 'center',
    margin: '3rem 0 2rem',
});

const FeatureCard = styled(Card)({
    textAlign: 'center',
    padding: '2rem',
    marginBottom: '1rem',
});

const StyledButton = styled(Button)({
    marginTop: '1rem',
});

function Home() {
    return (
        <div>
            {/* Hero Section */}
            <HeroSection>
                <Container>
                    <HeroContent>
                        <Typography variant="h2" component="h1" gutterBottom>
                            Borrow your next adventure
                        </Typography>
                        <Typography variant="h5" paragraph>
                            Discover a sustainable, hassle-free way to rent anything you need.
                        </Typography>
                        <StyledButton
                            variant="contained"
                            color="primary"
                            component={RouterLink}
                            to="/listings"
                            size="large"
                        >
                            Browse Marketplace
                        </StyledButton>
                    </HeroContent>
                </Container>
            </HeroSection>

            {/* Features Section */}
            <Container>
                <SectionTitle variant="h4" component="h2">
                    Why Choose Borrowed?
                </SectionTitle>
                <Grid container spacing={4}>
                    {['Sustainability', 'Passive Income', 'Secure Transactions'].map((feature) => (
                        <Grid item xs={12} sm={4} key={feature}>
                            <FeatureCard>
                                <Typography variant="h5" gutterBottom>{feature}</Typography>
                                <Typography>
                                    {feature === 'Sustainability' && 'Reduce waste and embrace a circular economy by sharing resources.'}
                                    {feature === 'Passive Income' && 'Earn money effortlessly by renting out your unused items.'}
                                    {feature === 'Secure Transactions' && 'Safe and verified transactions with multilayer security checks.'}
                                </Typography>
                            </FeatureCard>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* Social Proof Section */}
            <Container>
                <SectionTitle variant="h4" component="h2">
                    What Our Users Say
                </SectionTitle>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="body1">
                                    "Borrowed made it so easy for me to rent camping gear for a weekend trip. I’ll definitely use it again!"
                                </Typography>
                                <Typography variant="caption">— User Review</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="body1">
                                    "I earned over $500 renting out my kayak this summer, hassle-free!"
                                </Typography>
                                <Typography variant="caption">— Lender Testimonial</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            {/* Product Showcase */}
            <Container>
                <SectionTitle variant="h4" component="h2">
                    Popular Rentals
                </SectionTitle>
                <Grid container spacing={4}>
                    {['Kayaks', 'Camping Gear', 'Photography Equipment'].map((product) => (
                        <Grid item xs={12} sm={4} key={product}>
                            <Card>
                                <CardMedia
                                    image={`/${product.toLowerCase().replace(' ', '-')}.jpg`}
                                    title={product}
                                    style={{ height: '200px' }}
                                />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>{product}</Typography>
                                    <StyledButton variant="outlined" component={RouterLink} to={`/listings/${product.toLowerCase()}`}>
                                        View {product}
                                    </StyledButton>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* Newsletter Signup */}
            <Container>
                <SectionTitle variant="h4" component="h2">
                    Stay Updated
                </SectionTitle>
                <Box display="flex" justifyContent="center" marginBottom="2rem">
                    <TextField label="Enter your email" variant="outlined" />
                    <StyledButton variant="contained" color="primary" style={{ marginLeft: '1rem' }}>
                        Subscribe
                    </StyledButton>
                </Box>
            </Container>

            {/* Footer */}
            <Box bgcolor="lightgrey" padding="2rem">
                <Container>
                    <Typography variant="body1" align="center">
                        &copy; 2024 Borrowed Inc. | Privacy | Terms | Contact Us
                    </Typography>
                </Container>
            </Box>
        </div>
    );
}

export default Home;
