import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    Paper,
    Grid,
    CircularProgress,
    Box,
    Button,
    Divider,
    Avatar,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Snackbar,
    Rating,
    Modal,
    TextField,
    Card,
    CardContent,
    Chip,
} from '@mui/material';
import { styled } from '@mui/system';
import { getListing, getReviewsForListing, createTransaction, getUserBasicInfo, createConversation, sendMessage, getBlockedPeriods } from '../services/api';
import { useAuth } from '../context/AuthContext';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { addDays, addYears, differenceInDays, isWithinInterval, eachDayOfInterval, format } from 'date-fns';
import { MapContainer, TileLayer, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LocationOn, AttachMoney, Star } from '@mui/icons-material';


const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
}));

const MainImage = styled('img')({
    width: '100%',
    height: '400px', // Fixed height
    objectFit: 'cover',
    borderRadius: '8px 8px 0 0',
});

const ThumbnailContainer = styled(Box)({
    display: 'flex',
    overflowX: 'auto',
    marginTop: '8px',
    '&::-webkit-scrollbar': {
        height: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: '3px',
    },
});

const ThumbnailImage = styled('img')({
    width: '80px',
    height: '60px',
    objectFit: 'cover',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '8px',
    transition: 'opacity 0.3s',
    '&:hover': { opacity: 0.7 },
});

const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
}));

const FeatureChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const BookingCard = styled(Card)(({ theme }) => ({
    position: 'sticky',
    top: theme.spacing(2),
    zIndex: 1,
}));

const CalendarWrapper = styled(Box)({
    '& .react-datepicker': {
        fontFamily: 'Filson Soft, Arial, sans-serif',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
        borderRadius: '8px',
        width: '100%',
    },
    '& .react-datepicker__month-container': {
        width: '100%',
    },
    '& .react-datepicker__header': {
        backgroundColor: '#ffffff',
        borderBottom: 'none',
        padding: '0.5rem 0',
    },
    '& .react-datepicker__day--selected, & .react-datepicker__day--keyboard-selected': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .react-datepicker__current-month': {
        fontWeight: 500,
        fontSize: '1rem',
    },
    '& .react-datepicker__day': {
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
        color: '#333',
    },
    '& .react-datepicker__day:hover': {
        backgroundColor: '#e0e0e0',
    },
    '& .react-datepicker__day--disabled': {
        color: '#ccc',
        opacity: 0.5,
        pointerEvents: 'none',
    },
    '& .react-datepicker__day--outside-month': {
        color: '#000',
        opacity: 1,
    },
    '& .react-datepicker__day--in-range': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .react-datepicker__day--in-range:hover': {
        backgroundColor: '#45a049',
        color: 'white',
    },
    '& .react-datepicker__day--selected': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .react-datepicker__day--keyboard-selected': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .react-datepicker__day-name': {
        color: '#4CAF50',
    },
});


function ListingDetail() {
    const { currentUser } = useAuth();
    const [listing, setListing] = useState(null);
    const [ownerInfo, setOwnerInfo] = useState(null);
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(addDays(new Date(), 1));
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [blockedPeriods, setBlockedPeriods] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchListingAndReviews();
    }, [id]);

    const fetchListingAndReviews = async () => {
        try {
            setLoading(true);
            const [listingResponse, reviewsResponse, blockedPeriodsResponse] = await Promise.all([
                getListing(id),
                getReviewsForListing(id),
                getBlockedPeriods(id)
            ]);

            if (listingResponse?.data) {
                setListing(listingResponse.data);
                setReviews(reviewsResponse?.data || []);

                if (listingResponse.data.owner_id) {
                    const ownerResponse = await getUserBasicInfo(listingResponse.data.owner_id);
                    setOwnerInfo(ownerResponse?.data);
                }

                if (blockedPeriodsResponse?.data) {
                    setBlockedPeriods(blockedPeriodsResponse.data.map(period => ({
                        start: new Date(period.start_date),
                        end: new Date(period.end_date)
                    })));
                }
            } else {
                setError('Failed to load listing details. Please try again later.');
            }
        } catch (error) {
            console.error('Error fetching listing and reviews', error);
            setError('An error occurred while loading the listing. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
    };

    const handleOpenModal = () => {
        if (!currentUser) {
            setSnackbar({ open: true, message: 'Please log in to send a request.' });
            return;
        }
        const defaultMessage = `Hi, I would like to know if this is available from ${startDate.toDateString()} to ${endDate ? endDate.toDateString() : 'N/A'}. Thanks!`;
        setMessage(defaultMessage);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSendRequest = async () => {
        try {
            if (!currentUser || !currentUser.id) {
                setSnackbar({ open: true, message: 'Please log in to proceed.' });
                return;
            }

            const transactionData = {
                borrower_id: currentUser.id,
                lender_id: listing.owner_id,
                listing_id: listing.id,
                transaction_amount: listing.price,
                start_date: startDate.toISOString(),
                end_date: endDate ? endDate.toISOString() : startDate.toISOString(),
            };

            const transactionResponse = await createTransaction(transactionData);

            if (!transactionResponse || !transactionResponse.data || !transactionResponse.data.id) {
                throw new Error('Transaction creation failed, no transaction ID received.');
            }

            const transactionId = transactionResponse.data.id;

            const conversationData = {
                borrower_id: currentUser.id,
                lender_id: listing.owner_id,
                listing_id: listing.id,
                transaction_id: transactionId,
            };
            const conversationResponse = await createConversation(conversationData);

            if (!conversationResponse || !conversationResponse.data || !conversationResponse.data.id) {
                throw new Error('Conversation creation failed, no conversation ID received.');
            }

            const messageData = {
                conversation_id: conversationResponse.data.id,
                content: message,
                borrower_id: currentUser.id,
                lender_id: listing.owner_id
            };
            await sendMessage(messageData);

            setSnackbar({ open: true, message: 'Request sent successfully!' });
            handleCloseModal();

            navigate(`/transactions/${transactionId}`);
        } catch (error) {
            console.error('Error sending request', error);
            setSnackbar({ open: true, message: 'Failed to send request. Please try again.' });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const isDateBlocked = (date) => {
        return blockedPeriods.some(period =>
            isWithinInterval(date, { start: period.start, end: period.end })
        );
    };


    if (loading) {
        return (
            <StyledContainer maxWidth="lg">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <CircularProgress />
                </Box>
            </StyledContainer>
        );
    }

    if (error) {
        return (
            <StyledContainer maxWidth="lg">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <Typography color="error">{error}</Typography>
                </Box>
            </StyledContainer>
        );
    }

    if (!listing || !ownerInfo) {
        return (
            <StyledContainer maxWidth="lg">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <Typography>No listing information available.</Typography>
                </Box>
            </StyledContainer>
        );
    }

    const totalDays = endDate ? differenceInDays(endDate, startDate) + 1 : 0;
    const totalPrice = listing?.price ? (listing.price * totalDays).toFixed(2) : '0.00';

    return (
        <StyledContainer maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <StyledPaper>
                        {listing.images && listing.images.length > 0 ? (
                            <>
                                <MainImage src={listing.images[currentImageIndex]?.image_url} alt={listing.title} />
                                <ThumbnailContainer>
                                    {listing.images.map((image, index) => (
                                        <ThumbnailImage
                                            key={image.id || index}
                                            src={image.image_url}
                                            alt={`${listing.title} ${index + 1}`}
                                            onClick={() => handleImageClick(index)}
                                            style={{ opacity: index === currentImageIndex ? 0.6 : 1 }}
                                        />
                                    ))}
                                </ThumbnailContainer>
                            </>
                        ) : (
                            <Box
                                sx={{
                                    height: 0,
                                    paddingTop: '61.8%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'grey.200',
                                    borderRadius: '8px 8px 0 0',
                                }}
                            >
                                <Typography variant="h6">No Image Available</Typography>
                            </Box>
                        )}

                        <Box mt={3}>
                            <Typography variant="h4" gutterBottom>
                                {listing.title}
                            </Typography>
                            <Box display="flex" alignItems="center" mb={2}>
                                <LocationOn color="action" />
                                <Typography variant="subtitle1" ml={1}>
                                    {ownerInfo.zip_code}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mb={2}>
                                <AttachMoney color="action" />
                                <Typography variant="h6" color="primary" ml={1}>
                                    ${listing.price}/day
                                </Typography>
                            </Box>
                        </Box>

                        <Divider />

                        <SectionTitle variant="h5">
                            About this item
                        </SectionTitle>
                        <Typography variant="body1" paragraph>
                            {listing.description}
                        </Typography>

                        <Box my={2}>
                            <FeatureChip icon={<Star />} label="Top-rated item" />
                            <FeatureChip label="Instant book" />
                            <FeatureChip label="Free cancellation" />
                        </Box>

                        <Divider />

                        <SectionTitle variant="h5">
                            Lender Information
                        </SectionTitle>
                        <Box display="flex" alignItems="center" mb={2}>
                            <Avatar src={ownerInfo.profile_picture} alt={ownerInfo.first_name} sx={{ width: 64, height: 64, marginRight: 2 }}>
                                {ownerInfo.first_name?.[0]}
                            </Avatar>
                            <Box>
                                <Typography variant="h6">
                                    {ownerInfo.first_name} {ownerInfo.last_name}
                                </Typography>
                                <Rating value={ownerInfo.average_rating} readOnly size="small" />
                            </Box>
                        </Box>
                        <Typography variant="body2" color="text.secondary" mb={2}>
                            Borrowed covers damages up to $30,000 per item
                        </Typography>

                        <Divider />

                        <SectionTitle variant="h5">
                            Location
                        </SectionTitle>
                        <Box height={300} mb={3} borderRadius={2} overflow="hidden">
                            <MapContainer
                                center={[listing.location?.lat || 40.785091, listing.location?.lng || -73.968285]}
                                zoom={13}
                                style={{ height: '100%', width: '100%' }}
                            >
                                <TileLayer
                                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                    attribution=''
                                />
                                <Circle
                                    center={[listing.location?.lat || 40.785091, listing.location?.lng || -73.968285]}
                                    radius={1000}
                                    pathOptions={{ color: '#4CAF50' }}
                                />
                            </MapContainer>
                        </Box>

                        <Divider />

                        <SectionTitle variant="h5">
                            Reviews
                        </SectionTitle>
                        {reviews.length > 0 ? (
                            <List>
                                {reviews.map((review) => (
                                    <ListItem key={review.id} alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar>{(review.reviewer_first_name || '')[0]}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography component="span" variant="body1" color="text.primary">
                                                    {review.reviewer_first_name || ''} {review.reviewer_last_name || ''}
                                                </Typography>
                                            }
                                            secondary={
                                                <>
                                                    <Rating value={review.rating} readOnly size="small" />
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {review.comment}
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography>No reviews yet.</Typography>
                        )}
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <BookingCard>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                ${listing.price}/day
                            </Typography>
                            <CalendarWrapper>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(dates) => {
                                        const [start, end] = dates;
                                        setStartDate(start);
                                        setEndDate(end);
                                    }}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                    minDate={new Date()}
                                    maxDate={addYears(new Date(), 1)}
                                    monthsShown={1}
                                    excludeDates={blockedPeriods.flatMap(period =>
                                        eachDayOfInterval({ start: period.start, end: period.end })
                                    )}
                                    filterDate={date => !isDateBlocked(date)}
                                />
                            </CalendarWrapper>
                            {startDate && endDate && (
                                <Box mt={2}>
                                    <Typography variant="body2" gutterBottom>
                                        Pickup: {format(startDate, 'MMM d, yyyy')}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Drop off: {format(endDate, 'MMM d, yyyy')}
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                        Total: ${totalPrice}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {totalDays} {totalDays === 1 ? 'day' : 'days'}
                                    </Typography>
                                </Box>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={handleOpenModal}
                                sx={{ mt: 2 }}
                            >
                                Request to Book
                            </Button>
                            <Typography variant="caption" display="block" align="center" sx={{ mt: 1 }}>
                                You won't be charged yet
                            </Typography>
                        </CardContent>
                    </BookingCard>
                </Grid>
            </Grid>

            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="send-request-modal"
                aria-describedby="send-request-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography id="send-request-modal" variant="h6" component="h2" gutterBottom>
                        Send Request to {ownerInfo.first_name}
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        placeholder="Introduce yourself and ask any questions you may have..."
                    />
                    <Button
                        onClick={handleSendRequest}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        sx={{ mt: 2 }}
                    >
                        Send Request
                    </Button>
                </Box>
            </Modal>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
            />
        </StyledContainer>
    );
}

export default ListingDetail;