import React, { useState } from 'react';
import {
    TextField, Button, Container, Typography, Paper, Grid, Snackbar, MenuItem,
    Avatar, IconButton, Box, Card
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { register } from '../services/api';
import { useAuth } from '../context/AuthContext';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MapContainer, TileLayer, Circle, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(20),
    height: theme.spacing(20),
    margin: '0 auto',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    fontSize: '3rem',
}));

const MapCard = styled(Card)(({ theme }) => ({
    height: '300px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
}));

function LocationPicker({ onLocationChange }) {
    const map = useMapEvents({
        click(e) {
            onLocationChange(e.latlng);
        },
    });
    return null;
}

function Register() {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        first_name: '',
        last_name: '',
        zip_code: '',
        latitude: null,
        longitude: null,
    });
    const [error, setError] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const navigate = useNavigate();
    const { checkLoginStatus } = useAuth();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleLocationChange = (newLocation) => {
        setFormData(prev => ({
            ...prev,
            latitude: newLocation.lat,
            longitude: newLocation.lng
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await register(formData);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            await checkLoginStatus();
            setSnackbar({ open: true, message: 'Registration successful! Redirecting...' });
            setTimeout(() => navigate('/'), 2000);
        } catch (error) {
            console.error('Registration failed', error);
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <StyledContainer maxWidth="md">
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Register
            </Typography>
            <StyledPaper elevation={3}>
                <form onSubmit={handleSubmit}>
                    <ProfileAvatar>
                        {formData.first_name && formData.last_name ?
                            `${formData.first_name[0]}${formData.last_name[0]}` :
                            <AddAPhotoIcon fontSize="large" />
                        }
                    </ProfileAvatar>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                fullWidth
                                required
                                label="Username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                fullWidth
                                required
                                label="Password"
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                fullWidth
                                required
                                label="Email"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                fullWidth
                                required
                                label="First Name"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                fullWidth
                                required
                                label="Last Name"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                fullWidth
                                required
                                label="Zip Code"
                                name="zip_code"
                                value={formData.zip_code}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Location</Typography>
                            <MapCard>
                                <MapContainer
                                    center={[formData.latitude || 40.785091, formData.longitude || -73.968285]}
                                    zoom={13}
                                    style={{ height: '100%', width: '100%' }}
                                >
                                    <TileLayer
                                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                        attribution=''
                                    />
                                    {formData.latitude && formData.longitude && (
                                        <Circle
                                            center={[formData.latitude, formData.longitude]}
                                            radius={1000}
                                            pathOptions={{ color: '#4CAF50' }}
                                        />
                                    )}
                                    <LocationPicker onLocationChange={handleLocationChange} />
                                </MapContainer>
                            </MapCard>
                            <Typography variant="body2" color="textSecondary" align="center">
                                <LocationOnIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                                Click on the map to set your location
                            </Typography>
                        </Grid>
                    </Grid>
                    {error && (
                        <Typography color="error" align="center" gutterBottom>
                            {error}
                        </Typography>
                    )}
                    <StyledButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        Register
                    </StyledButton>
                </form>
            </StyledPaper>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
            />
        </StyledContainer>
    );
}

export default Register;