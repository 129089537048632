import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Grid,
    CircularProgress,
    TextField,
    Button,
    Box,
    Avatar,
    IconButton,
    Snackbar,
    Divider,
    Card,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MapContainer, TileLayer, Circle, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { getCurrentUser, uploadProfilePicture, deleteProfilePicture, logout, updateUser } from '../services/api';
import { useAuth } from '../context/AuthContext';

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(20),
    height: theme.spacing(20),
    marginRight: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
    },
}));

const ProfileInfo = styled(Box)(({ theme }) => ({
    flex: 1,
}));

const MapCard = styled(Card)(({ theme }) => ({
    height: '300px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const ActionButtons = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    '& > button': {
        marginLeft: theme.spacing(1),
    },
}));

function LocationPicker({ onLocationChange }) {
    const map = useMapEvents({
        click(e) {
            onLocationChange(e.latlng);
        },
    });
    return null;
}

function Profile() {
    const [user, setUser] = useState(null);
    const [editableUser, setEditableUser] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const [newPassword, setNewPassword] = useState('');
    const { logout: authLogout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserProfile();
    }, []);

    const fetchUserProfile = async () => {
        try {
            const response = await getCurrentUser();
            setUser(response.data);
            setEditableUser(response.data);
        } catch (err) {
            console.error('Error fetching user profile:', err);
            setError('Failed to load user profile. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
        if (!isEditing) {
            setEditableUser({ ...user });
            setNewPassword('');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditableUser({ ...editableUser, [name]: value });
    };

    const handleSave = async () => {
        try {
            const dataToUpdate = { ...editableUser };
            if (newPassword) {
                dataToUpdate.password = newPassword;
            }
            await updateUser(dataToUpdate);
            setUser(dataToUpdate);
            setIsEditing(false);
            setSnackbar({ open: true, message: 'Profile updated successfully' });
        } catch (err) {
            console.error('Error updating user profile:', err);
            setError('Failed to update profile. Please try again.');
        }
    };

    const handleProfilePictureUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                await uploadProfilePicture(file);
                fetchUserProfile();
                setSnackbar({ open: true, message: 'Profile picture uploaded successfully' });
            } catch (err) {
                console.error('Error uploading profile picture:', err);
                setError('Failed to upload profile picture. Please try again.');
            }
        }
    };

    const handleProfilePictureDelete = async () => {
        try {
            await deleteProfilePicture();
            fetchUserProfile();
            setSnackbar({ open: true, message: 'Profile picture deleted successfully' });
        } catch (err) {
            console.error('Error deleting profile picture:', err);
            setError('Failed to delete profile picture. Please try again.');
        }
    };

    const handleLogout = async () => {
        try {
            await logout();
            authLogout();
            navigate('/');
        } catch (error) {
            console.error('Error logging out:', error);
            setError('Failed to log out. Please try again.');
        }
    };

    const handleLocationChange = (newLocation) => {
        setEditableUser(prev => ({
            ...prev,
            latitude: newLocation.lat,
            longitude: newLocation.lng
        }));
    };

    if (loading) {
        return (
            <StyledContainer maxWidth="md">
                <CircularProgress />
            </StyledContainer>
        );
    }

    if (error) {
        return (
            <StyledContainer maxWidth="md">
                <Typography color="error">{error}</Typography>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer maxWidth="md">
            {user && (
                <>
                    <ProfileHeader>
                        <ProfileAvatar src={user.profile_picture}>
                            {!user.profile_picture && `${user.first_name[0]}${user.last_name[0]}`}
                        </ProfileAvatar>
                        <ProfileInfo>
                            <Typography variant="h4" gutterBottom>{`${user.first_name} ${user.last_name}`}</Typography>
                            <Typography variant="body1" color="textSecondary" gutterBottom>{user.email}</Typography>
                            <Box>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="profile-picture-upload"
                                    type="file"
                                    onChange={handleProfilePictureUpload}
                                />
                                <label htmlFor="profile-picture-upload">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        component="span"
                                        startIcon={<AddAPhotoIcon />}
                                    >
                                        Update Photo
                                    </Button>
                                </label>
                                {user.profile_picture && (
                                    <IconButton color="secondary" onClick={handleProfilePictureDelete}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </ProfileInfo>
                    </ProfileHeader>

                    <Divider style={{ margin: '24px 0' }} />

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>Personal Information</Typography>
                            {isEditing ? (
                                <>
                                    <StyledTextField
                                        fullWidth
                                        label="First Name"
                                        name="first_name"
                                        value={editableUser.first_name}
                                        onChange={handleInputChange}
                                    />
                                    <StyledTextField
                                        fullWidth
                                        label="Last Name"
                                        name="last_name"
                                        value={editableUser.last_name}
                                        onChange={handleInputChange}
                                    />
                                    <StyledTextField
                                        fullWidth
                                        label="Username"
                                        name="username"
                                        value={editableUser.username}
                                        onChange={handleInputChange}
                                    />
                                    <StyledTextField
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        value={editableUser.email}
                                        onChange={handleInputChange}
                                    />
                                    <StyledTextField
                                        fullWidth
                                        label="Zip Code"
                                        name="zip_code"
                                        value={editableUser.zip_code}
                                        onChange={handleInputChange}
                                    />
                                    <StyledTextField
                                        fullWidth
                                        label="New Password"
                                        name="newPassword"
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </>
                            ) : (
                                <>
                                    <Typography><strong>Username:</strong> {user.username}</Typography>
                                    <Typography><strong>Email:</strong> {user.email}</Typography>
                                    <Typography><strong>Zip Code:</strong> {user.zip_code}</Typography>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>Location</Typography>
                            <MapCard>
                                <MapContainer
                                    center={[editableUser.latitude || 40.785091, editableUser.longitude || -73.968285]}
                                    zoom={13}
                                    style={{ height: '100%', width: '100%' }}
                                >
                                    <TileLayer
                                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                        attribution=''
                                    />
                                    <Circle
                                        center={[editableUser.latitude || 40.785091, editableUser.longitude || -73.968285]}
                                        radius={1000}
                                        pathOptions={{ color: '#4CAF50' }}
                                    />
                                    <LocationPicker onLocationChange={handleLocationChange} />
                                </MapContainer>
                            </MapCard>
                            <Typography variant="body2" color="textSecondary" align="center">
                                <LocationOnIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                                {isEditing ? "Click on the map to update your location" : "Your current location"}
                            </Typography>
                        </Grid>
                    </Grid>

                    <ActionButtons>
                        {isEditing ? (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    onClick={handleSave}
                                >
                                    Save Changes
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    startIcon={<CancelIcon />}
                                    onClick={handleEditToggle}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    onClick={handleEditToggle}
                                >
                                    Edit Profile
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </Button>
                            </>
                        )}
                    </ActionButtons>
                </>
            )}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
            />
        </StyledContainer>
    );
}

export default Profile;