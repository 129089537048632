// src/services/api.js

import axios from 'axios';

const API_URL = 'https://getborrowed.com/api/v1';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Flag to prevent multiple token refresh requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
            console.log('Added access token to request headers');
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;

        // Check if the error is due to unauthorized access
        if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry &&
            !originalRequest.url.includes('/refresh-token')
        ) {
            console.log('Received 401 error, attempting to refresh token');
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return api(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = localStorage.getItem('refresh_token');
            if (!refreshToken) {
                // No refresh token, logout the user
                console.error('No refresh token available');
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                window.location.href = '/login';
                return Promise.reject(error);
            }

            return new Promise(function (resolve, reject) {
                axios
                    .post(
                        `${API_URL}/users/refresh-token`,
                        { refresh_token: refreshToken },
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
                    )
                    .then(({ data }) => {
                        console.log('Token refreshed successfully');
                        localStorage.setItem('access_token', data.access_token);
                        api.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                        processQueue(null, data.access_token);
                        resolve(api(originalRequest));
                    })
                    .catch((err) => {
                        console.error('Token refresh failed', err);
                        processQueue(err, null);
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        window.location.href = '/login';
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(error);
    }
);

// Authentication functions

export const register = (userData) => {
    return api.post('/users/register', userData);
};

export const login = (username, password, expoToken) => {
    return api.post('/users/login', { username, password, expo_token: expoToken });
};

export const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return Promise.resolve();
};

export const getCurrentUser = () => {
    return api.get('/users/me');
};

export const updateUser = (userData) => {
    return api.put('/users/update', userData);
};

// export const getListings = (limit = 10, skip = 0) => {
//     return api.get(`/listings/?limit=${limit}&skip=${skip}`);
// };

// export const searchListings = (query, limit = 20, skip = 0) => {
//     return api.get(`/listings/search/?query=${query}&limit=${limit}&skip=${skip}`);
// };

export const createListing = (listingData) => {
    return api.post('/listings/', listingData);
};

export const getListing = (listingId) => {
    return api.get(`/listings/${listingId}`);
};

export const updateListing = (listingId, listingData) => {
    return api.put(`/listings/${listingId}`, listingData);
};

export const deleteListing = (listingId) => {
    return api.delete(`/listings/${listingId}`);
};

export const getReviewsForListing = (listingId) => {
    return api.get(`/listings/${listingId}/reviews`);
};

export const createReviewForListing = (listingId, reviewData) => {
    return api.post(`/listings/${listingId}/reviews`, reviewData);
};

export const getBlockedPeriods = (listingId) => {
    return api.get(`/listings/${listingId}/blocked-periods`);
};

export const createBlockedPeriod = (listingId, periodData) => {
    return api.post(`/listings/${listingId}/blocked-periods`, periodData);
};

export const deleteBlockedPeriod = (listingId, blockedPeriodId) => {
    return api.delete(`/listings/${listingId}/blocked-periods/${blockedPeriodId}`);
};

export const createTransaction = (transactionData) => {
    return api.post('/transactions/', transactionData);
};

export const getTransaction = (transactionId) => {
    return api.get(`/transactions/${transactionId}`);
};

export const updateTransactionStatus = (transactionId, statusData) => {
    return api.put(`/transactions/${transactionId}/status`, statusData);
};

export const getCurrentUserTransactions = () => {
    return api.get('/transactions/user/me');
};

export const getUserListings = () => {
    return api.get('/listings/me/');
};

export const getConversations = () => {
    return api.get('/messaging/conversations');
};

export const createConversation = (conversationData) => {
    return api.post('/messaging/conversations', conversationData);
};

export const getConversation = (conversationId) => {
    return api.get(`/messaging/conversations/${conversationId}`);
};

export const getConversationMessages = (conversationId) => {
    return api.get(`/messaging/conversations/${conversationId}/messages`);
};

export const sendMessage = (messageData) => {
    return api.post('/messaging/messages', messageData);
};

export const markMessageAsRead = (messageId) => {
    return api.post(`/messaging/messages/${messageId}/read`);
};

export const updateExpoToken = (tokenData) => {
    return api.put('/users/update-expo-token', tokenData);
};

export const uploadProfilePicture = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return api.post('/users/profile-picture/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const deleteProfilePicture = () => {
    return api.delete('/users/delete-pic/');
};

export const uploadListingImages = (listingId, files) => {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));
    return api.post(`/listings/${listingId}/upload-images`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const deleteListingImages = (listingId, imageUrls) => {
    return api.delete(`/listings/${listingId}/delete-images`, { data: imageUrls });
};

export const reportUser = (userIdToReport) => {
    return api.post(`/users/report/${userIdToReport}`);
};

export const getUserBasicInfo = (userId) => {
    return api.get(`/users/basic_info/${userId}`);
};

export const getUserConversations = () => {
    return api.get('/messaging/conversations');
};

export const getUserTransactions = () => {
    return api.get('/transactions/user/me');
};

export const sendContactForm = () => {
    return api.get('/not-implemented');
};

export const getConversationByTransactionId = (transactionId) => {
    return api.get(`/messaging/conversations/transaction/${transactionId}`);
};

export const updateUserLocation = async (location) => {
    // Implement the API call to update the user's location
    // This should send a PUT or PATCH request to your backend
    // with the new location data 
    // PLACEHOLDER
};

export const getListings = (limit = 10, skip = 0, category_id = null) => {
    return api.get(`/listings/?limit=${limit}&skip=${skip}${category_id ? `&category_id=${category_id}` : ''}`);
};

export const searchListings = (query, limit = 20, skip = 0) => {
    return api.get(`/listings/search/?query=${query}&limit=${limit}&skip=${skip}`);
};

// New functions for category handling
export const getTopLevelCategories = () => {
    return api.get('/categories/top-level');
};

export const getSubcategories = (categoryId) => {
    return api.get(`/categories/${categoryId}/subcategories`);
};

export const getCategoryWithDepth = (categoryId, maxDepth = 2) => {
    return api.get(`/categories/${categoryId}?max_depth=${maxDepth}`);
}

export const joinWaitlist = (email, referralCode = null, recaptchaToken) => {
    const data = { email, recaptcha_token: recaptchaToken };
    if (referralCode) {
        data.referral_code = referralCode;
    }
    return api.post('/waitlist/', data);
};

export default api;