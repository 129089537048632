import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Container, Typography, Paper, Grid, CircularProgress, Box, Button, TextField, Snackbar,
    Tooltip, IconButton, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, DialogActions, Chip, Card
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { styled } from '@mui/system';
import { getListing, updateListing, uploadListingImages, getBlockedPeriods, createBlockedPeriod, getTopLevelCategories, getSubcategories } from '../services/api';
import { useAuth } from '../context/AuthContext';
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/colors/green.css";
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';


function LocationPicker({ onLocationChange, locations }) {
    const map = useMapEvents({
        click(e) {
            if (locations.length < 3) {
                onLocationChange(e.latlng);
            }
        },
    });
    return null;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
}));

const MainImage = styled('img')({
    width: '100%',
    height: '400px',
    objectFit: 'cover',
    borderRadius: '8px',
    marginBottom: '16px',
});

const ThumbnailImage = styled('img')({
    width: '100%',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'opacity 0.3s',
    '&:hover': { opacity: 0.7 },
});
const CalendarWrapper = styled(Box)({
    '& .rmdp-wrapper': {
        fontFamily: 'Filson Soft, Arial, sans-serif',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)', // Add shadow for consistency
        borderRadius: '8px', // Smooth edges
    },
    '& .rmdp-header': {
        backgroundColor: '#ffffff',
        borderBottom: 'none',
        padding: '0.5rem 0', // Consistent spacing
    },
    '& .rmdp-day.selected': {
        backgroundColor: '#4CAF50', // Green for selected days
        color: 'white', // White text for contrast
    },
    '& .rmdp-day.range': {
        backgroundColor: '#4CAF50', // Green for days in range
        color: 'white',
    },
    '& .rmdp-day.in-range:hover': {
        backgroundColor: '#45a049', // Darker green on hover for range
        color: 'white',
    },
    '& .rmdp-day.today': {
        border: '1px solid #4CAF50', // Green border for today's date
    },
    '& .rmdp-week-day': {
        color: '#4CAF50', // Green for day names
    },
    '& .rmdp-day.disabled': {
        color: '#ccc', // Grey for disabled (past) days
        pointerEvents: 'none', // Disable interaction with past days
        opacity: 0.5, // Faded appearance for disabled days
    },
    '& .rmdp-day.outside': {
        color: '#000', // Black for days outside current month
        opacity: 1,
    },
    '& .rmdp-panel-body li .b-date': {
        backgroundColor: '#4CAF50', // No background
        border: 'none',
        // fontSize: '14px', // Match size
        // padding: '4px', // Add padding
    },
    '& .rmdp-panel-body li button': {
        color: '#fff', // White text for buttons
        cursor: 'pointer', // Make buttons interactive
    },
    '& .rmdp-panel-body li .b-deselect': {
        backgroundColor: '#4CAF50', // Blue for deselect button
        border: 'none',
        fontSize: '17px', // Font size for deselect button
    },
    '& .rmdp-panel-header': {
        // backgroundColor: '#4CAF50', // Blue for deselect button
        // border: 'none',
        // fontSize: '17px', // Font size for deselect button
        content: '"Your Custom Text"',
    },
    '& .rmdp-date-panel': {
        fontFamily: 'Filson Soft, Arial, sans-serif',
        backgroundColor: '#000',
        borderRadius: '8px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)', // Add shadow for DatePanel consistency
    },
    '& .rmdp-date': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .rmdp-range': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .rmdp-clear-button': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
});

const MapCard = styled(Card)(({ theme }) => ({
    height: '300px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
}));


function MyListing() {
    const { currentUser } = useAuth();
    const [listing, setListing] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [blockedPeriods, setBlockedPeriods] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const [images, setImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryPath, setCategoryPath] = useState([]);
    const { id } = useParams();
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        fetchListingAndBlockedPeriods();
    }, [id]);

    const fetchListingAndBlockedPeriods = async () => {
        try {
            setLoading(true);
            const [listingResponse, blockedPeriodsResponse] = await Promise.all([
                getListing(id),
                getBlockedPeriods(id)
            ]);

            if (listingResponse?.data) {
                setListing(listingResponse.data);
                setTitle(listingResponse.data.title);
                setDescription(listingResponse.data.description);
                setPrice(listingResponse.data.price);
                setImages(listingResponse.data.images);
                setSelectedCategory(listingResponse.data.category);
                setLocations(listingResponse.data.locations || []);
            }

            if (blockedPeriodsResponse?.data) {
                setBlockedPeriods(blockedPeriodsResponse.data.map(period => [
                    new Date(period.start_date),
                    new Date(period.end_date)
                ]));
            }
        } catch (error) {
            console.error('Error fetching listing and blocked periods', error);
            setError('An error occurred while loading the listing. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleLocationChange = (newLocation) => {
        if (locations.length < 3) {
            setLocations([...locations, { latitude: newLocation.lat, longitude: newLocation.lng }]);
        }
    };

    const handleLocationDelete = (index) => {
        setLocations(locations.filter((_, i) => i !== index));
    };

    const fetchTopLevelCategories = async () => {
        try {
            const response = await getTopLevelCategories();
            setCategories(response.data);
            setCategoryPath([]);
        } catch (error) {
            console.error('Error fetching top-level categories:', error);
            setSnackbar({ open: true, message: 'Failed to load categories. Please try again.' });
        }
    };

    const handleCategorySelect = async (category) => {
        try {
            const response = await getSubcategories(category.id);
            if (response.data.length > 0) {
                setCategories(response.data);
                setCategoryPath([...categoryPath, category]);
            } else {
                setSelectedCategory(category);
                setCategoryDialogOpen(false);
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
            setSnackbar({ open: true, message: 'Failed to load subcategories. Please try again.' });
        }
    };

    const handleCategoryBack = async () => {
        if (categoryPath.length > 1) {
            const newPath = [...categoryPath];
            newPath.pop();
            setCategoryPath(newPath);
            const parentCategory = newPath[newPath.length - 1];
            const response = await getSubcategories(parentCategory.id);
            setCategories(response.data);
        } else {
            fetchTopLevelCategories();
        }
    };

    const handleSave = async () => {
        try {
            const updatedListing = {
                ...listing,
                title,
                description,
                price: parseFloat(price),
                category_id: selectedCategory.id,
                locations: locations,
            };
            await updateListing(id, updatedListing);
            setSnackbar({ open: true, message: 'Listing updated successfully!' });
        } catch (error) {
            console.error('Error updating listing', error);
            setSnackbar({ open: true, message: 'Failed to update listing. Please try again.' });
        }
    };

    const handleSaveBlockedPeriods = async () => {
        try {
            for (const [start, end] of blockedPeriods) {
                await createBlockedPeriod(id, {
                    start_date: start.toISOString(),
                    end_date: end.toISOString()
                });
            }
            setSnackbar({ open: true, message: 'Blocked periods saved successfully!' });
        } catch (error) {
            console.error('Error saving blocked periods', error);
            setSnackbar({ open: true, message: 'Failed to save blocked periods. Please try again.' });
        }
    };

    const handleImageUpload = async (event) => {
        const files = Array.from(event.target.files);
        try {
            const formData = new FormData();
            files.forEach((file) => formData.append('images', file));
            const response = await uploadListingImages(id, formData);
            setImages([...images, ...response.data]);
            setSnackbar({ open: true, message: 'Images uploaded successfully!' });
        } catch (error) {
            console.error('Error uploading images', error);
            setSnackbar({ open: true, message: 'Failed to upload images. Please try again.' });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
    };

    if (loading) {
        return (
            <Container maxWidth="md" style={{ marginTop: '50px', textAlign: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="md" style={{ marginTop: '50px', textAlign: 'center' }}>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" style={{ marginTop: '50px' }}>
            <StyledPaper elevation={3}>
                <Typography variant="h4" gutterBottom>Edit Listing</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={7}>
                        {images && images.length > 0 ? (
                            <>
                                <MainImage src={images[currentImageIndex]?.image_url} alt={title} />
                                {images.length > 1 && (
                                    <Grid container spacing={1}>
                                        {images.map((image, index) => (
                                            <Grid item xs={3} key={image.id || index}>
                                                <ThumbnailImage
                                                    src={image.image_url}
                                                    alt={`${title} ${index + 1}`}
                                                    onClick={() => handleImageClick(index)}
                                                    style={{ opacity: index === currentImageIndex ? 0.6 : 1 }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </>
                        ) : (
                            <Box
                                sx={{
                                    height: 400,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'grey.200',
                                    borderRadius: 2,
                                    mb: 2,
                                }}
                            >
                                <Typography variant="h6">No Image Available</Typography>
                            </Box>
                        )}
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={handleImageUpload}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" component="span" fullWidth style={{ marginTop: '10px' }}>
                                Upload Images
                            </Button>
                        </label>
                        <TextField
                            fullWidth
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Price per day"
                            type="number"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Category"
                            value={selectedCategory ? selectedCategory.name : ''}
                            onClick={() => {
                                setCategoryDialogOpen(true);
                                fetchTopLevelCategories();
                            }}
                            InputProps={{ readOnly: true }}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Paper elevation={2} style={{ padding: '16px' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Blocked Periods
                                <Tooltip title="Select date ranges you want to block. Click 'Save Blocked Periods' when done.">
                                    <IconButton size="small">
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                            <CalendarWrapper>
                                <Calendar
                                    value={blockedPeriods}
                                    onChange={setBlockedPeriods}
                                    multiple
                                    range
                                    plugins={[
                                        <DatePanel position="bottom" header='Unavailable Dates' />
                                    ]}
                                    minDate={new Date()}
                                    autoFocus={true}
                                    highlightToday={false}
                                    onOpenPickNewDate={false}
                                />
                            </CalendarWrapper>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSaveBlockedPeriods}
                                fullWidth
                                style={{ marginTop: '10px' }}
                            >
                                Save Blocked Periods
                            </Button>
                            <Typography variant="caption" display="block" style={{ marginTop: '10px', textAlign: 'center' }}>
                                Select date ranges to block and click 'Save Blocked Periods'.
                            </Typography>
                        </Paper>
                        <Paper elevation={2} style={{ padding: '16px', marginTop: '20px' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Item Locations (up to 3)
                            </Typography>
                            <MapCard>
                                <MapContainer
                                    center={locations[0] ? [locations[0].latitude, locations[0].longitude] : [40.7128, -74.0060]}
                                    zoom={13}
                                    style={{ height: '300px', width: '100%' }}
                                >
                                    <TileLayer
                                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                    />
                                    {locations.map((location, index) => (
                                        <Marker key={index} position={[location.latitude, location.longitude]} />
                                    ))}
                                    <LocationPicker onLocationChange={handleLocationChange} locations={locations} />
                                </MapContainer>
                            </MapCard>
                            <Box mt={1}>
                                {locations.map((location, index) => (
                                    <Chip
                                        key={index}
                                        icon={<LocationOnIcon />}
                                        label={`Location ${index + 1}`}
                                        onDelete={() => handleLocationDelete(index)}
                                        style={{ margin: '5px' }}
                                    />
                                ))}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    fullWidth
                    style={{ marginTop: '20px' }}
                >
                    Save Changes
                </Button>
            </StyledPaper>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
            />
            <Dialog open={categoryDialogOpen} onClose={() => setCategoryDialogOpen(false)}>
                <DialogTitle>
                    {categoryPath.length > 0 && (
                        <IconButton edge="start" color="inherit" onClick={handleCategoryBack} aria-label="back">
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    Select category
                </DialogTitle>
                <DialogContent>
                    <List>
                        {categories.map((category) => (
                            <ListItem button key={category.id} onClick={() => handleCategorySelect(category)}>
                                <ListItemText primary={category.name} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCategoryDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default MyListing;