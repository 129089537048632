import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
}));

function AboutUs() {
    return (
        <Container maxWidth="md" style={{ marginTop: '50px' }}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                About Us
            </Typography>
            <StyledPaper>
                <Typography variant="body1" paragraph>
                    Borrowed is a platform that connects people who want to lend items with those who need to borrow them. Our mission is to promote a sharing economy and reduce waste by making it easy for people to borrow items they need temporarily.
                </Typography>
                <Typography variant="body1" paragraph>
                    Founded in 2023, we've been working hard to create a user-friendly platform that benefits both lenders and borrowers. We believe in the power of community and the positive impact that sharing resources can have on our environment and our wallets.
                </Typography>
            </StyledPaper>
        </Container>
    );
}

export default AboutUs;