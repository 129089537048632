import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Typography,
    Grid,
    CircularProgress,
    Box,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Chip,
    Button,
    Rating,
    TextField,
    InputAdornment,
    Skeleton,
} from '@mui/material';
import { styled } from '@mui/system';
import { Search, LocationOn } from '@mui/icons-material';
import { getListings, searchListings, getTopLevelCategories, getSubcategories } from '../services/api';

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
}));

const StyledCardMedia = styled(CardMedia)({
    height: 200,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const PriceTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
}));

const LocationTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
    cursor: 'pointer',
}));

const ITEMS_PER_PAGE = 12;

function Listings() {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [error, setError] = useState('');
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);

    useEffect(() => {
        fetchTopLevelCategories();
    }, []);

    useEffect(() => {
        if (!searchQuery && !selectedCategory) {
            fetchListings();
        }
    }, [page, searchQuery, selectedCategory]);

    const fetchTopLevelCategories = async () => {
        try {
            setCategoriesLoading(true);
            const response = await getTopLevelCategories();
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories', error);
            setError('Failed to fetch categories. Please try again later.');
        } finally {
            setCategoriesLoading(false);
        }
    };

    const fetchSubcategories = async (categoryId) => {
        try {
            setCategoriesLoading(true);
            const response = await getSubcategories(categoryId);
            setSubcategories(response.data);
        } catch (error) {
            console.error('Error fetching subcategories', error);
            setError('Failed to fetch subcategories. Please try again later.');
        } finally {
            setCategoriesLoading(false);
        }
    };

    const fetchListings = async () => {
        try {
            setLoading(true);
            const response = await getListings(ITEMS_PER_PAGE, page * ITEMS_PER_PAGE, selectedCategory?.id);
            if (response.data.length < ITEMS_PER_PAGE) {
                setHasMore(false);
            }
            setListings(prevListings => page === 0 ? response.data : [...prevListings, ...response.data]);
        } catch (error) {
            console.error('Error fetching listings', error);
            setError('Failed to fetch listings. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = async (query) => {
        setSearchQuery(query);
        setPage(0);
        setSelectedCategory(null);
        setSubcategories([]);
        try {
            setLoading(true);
            const response = await searchListings(query);
            setListings(response.data);
            setHasMore(false);
        } catch (error) {
            console.error('Error searching listings', error);
            setError('Failed to search listings. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handleClearFilters = () => {
        setSearchQuery('');
        setSelectedCategory(null);
        setSubcategories([]);
        setPage(0);
        setHasMore(true);
        fetchListings();
    };

    const handleCategoryClick = async (category) => {
        if (selectedCategory?.id === category.id) {
            setSelectedCategory(null);
            setSubcategories([]);
        } else {
            setSelectedCategory(category);
            await fetchSubcategories(category.id);
        }
        setSearchQuery('');
        setPage(0);
        setHasMore(true);
        fetchListings();
    };

    const handleSubcategoryClick = (subcategory) => {
        setSelectedCategory(subcategory);
        setPage(0);
        setHasMore(true);
        fetchListings();
    };

    if (error) {
        return (
            <StyledContainer maxWidth="lg">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <Typography color="error">{error}</Typography>
                </Box>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer maxWidth="lg">
            <Box mb={3}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search listings..."
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Box mb={3}>
                {categoriesLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={40} />
                ) : (
                    categories.map((category) => (
                        <CategoryChip
                            key={category.id}
                            label={category.name}
                            onClick={() => handleCategoryClick(category)}
                            color={selectedCategory?.id === category.id ? "primary" : "default"}
                        />
                    ))
                )}
            </Box>

            {subcategories.length > 0 && (
                <Box mb={3}>
                    <Typography variant="subtitle1" gutterBottom>Subcategories:</Typography>
                    {categoriesLoading ? (
                        <Skeleton variant="rectangular" width="100%" height={40} />
                    ) : (
                        subcategories.map((subcategory) => (
                            <CategoryChip
                                key={subcategory.id}
                                label={subcategory.name}
                                onClick={() => handleSubcategoryClick(subcategory)}
                                color={selectedCategory?.id === subcategory.id ? "secondary" : "default"}
                            />
                        ))
                    )}
                </Box>
            )}

            {(searchQuery || selectedCategory) && (
                <Box mb={3}>
                    <Typography variant="body2">
                        {searchQuery && `Showing results for "${searchQuery}"`}
                        {selectedCategory && `Showing results for category: ${selectedCategory.name}`}
                        <Button onClick={handleClearFilters} size="small" style={{ marginLeft: '10px' }}>
                            Clear Filters
                        </Button>
                    </Typography>
                </Box>
            )}

            <Grid container spacing={3}>
                {listings.map((listing) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={listing.id}>
                        <CardActionArea component={Link} to={`/listings/${listing.id}`}>
                            <StyledCard>
                                <StyledCardMedia
                                    image={listing.images && listing.images.length > 0 ? listing.images[0].image_url : '/placeholder-image.jpg'}
                                    title={listing.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2" noWrap>
                                        {listing.title}
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <PriceTypography variant="body1">
                                            ${listing.price}/day
                                        </PriceTypography>
                                        <Rating value={listing.average_rating || 0} readOnly size="small" />
                                    </Box>
                                    <LocationTypography>
                                        <LocationOn fontSize="small" style={{ marginRight: '4px' }} />
                                        {listing.owner_province || 'Location N/A'}
                                    </LocationTypography>
                                    <Typography variant="body2" color="text.secondary" mt={1}>
                                        {listing.total_reviews || 0} reviews
                                    </Typography>
                                </CardContent>
                            </StyledCard>
                        </CardActionArea>
                    </Grid>
                ))}
            </Grid>

            {loading && (
                <Box mt={3} display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}

            {!loading && hasMore && !searchQuery && !selectedCategory && (
                <Box mt={3} display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" onClick={handleLoadMore}>
                        Load More
                    </Button>
                </Box>
            )}
        </StyledContainer>
    );
}

export default Listings;