import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Grid,
    Button,
    CircularProgress,
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    Chip,
    Box,
    IconButton,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getUserListings } from '../services/api';

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const ListingCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': {
        transform: 'scale(1.03)',
    },
}));

const ListingCardMedia = styled(CardMedia)(({ theme }) => ({
    paddingTop: '56.25%', // 16:9 aspect ratio
    position: 'relative',
}));

const ListingCardContent = styled(CardContent)(({ theme }) => ({
    flexGrow: 1,
}));

const PriceChip = styled(Chip)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
}));

const VisibilityChip = styled(Chip)(({ theme, ispublic }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: ispublic === 'true' ? theme.palette.success.main : theme.palette.error.main,
    color: theme.palette.primary.contrastText,
}));

function MyListings() {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserListings();
    }, []);

    const fetchUserListings = async () => {
        try {
            setLoading(true);
            const response = await getUserListings();
            setListings(response.data);
        } catch (err) {
            console.error('Error fetching user listings:', err);
            setError('Failed to load your listings. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleEditListing = (id, event) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(`/my-listings/${id}`);
    };

    if (loading) {
        return (
            <StyledContainer>
                <CircularProgress />
            </StyledContainer>
        );
    }

    if (error) {
        return (
            <StyledContainer>
                <Typography color="error">{error}</Typography>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4" component="h1">
                    My Listings
                </Typography>
                <StyledButton
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/create-listing"
                    startIcon={<AddIcon />}
                >
                    Add Listing
                </StyledButton>
            </Box>
            <Grid container spacing={3}>
                {listings.map((listing) => (
                    <Grid item xs={12} sm={6} md={4} key={listing.id}>
                        <ListingCard>
                            <CardActionArea component={Link} to={`/my-listings/${listing.id}`}>
                                <ListingCardMedia
                                    image={listing.images[0]?.image_url || '/placeholder-image.jpg'}
                                    title={listing.title}
                                >
                                    <PriceChip label={`$${parseFloat(listing.price).toFixed(2)}`} />
                                    <VisibilityChip
                                        icon={listing.is_public ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        label={listing.is_public ? 'Public' : 'Private'}
                                        ispublic={listing.is_public.toString()}
                                    />
                                </ListingCardMedia>
                                <ListingCardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        {listing.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" noWrap>
                                        {listing.description}
                                    </Typography>
                                </ListingCardContent>
                            </CardActionArea>
                            <Box display="flex" justifyContent="flex-end" p={1}>
                                <Tooltip title="Edit Listing">
                                    <IconButton
                                        size="small"
                                        onClick={(event) => handleEditListing(listing.id, event)}
                                        aria-label="edit"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </ListingCard>
                    </Grid>
                ))}
            </Grid>
        </StyledContainer>
    );
}

export default MyListings;