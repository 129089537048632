// src/context/ModeContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';

const ModeContext = createContext(null);

export const ModeProvider = ({ children }) => {
    const [mode, setMode] = useState('Borrowing'); // Default mode

    useEffect(() => {
        const storedMode = localStorage.getItem('app_mode');
        if (storedMode) {
            setMode(storedMode);
        }
    }, []);

    const toggleMode = () => {
        const newMode = mode === 'Borrowing' ? 'Lending' : 'Borrowing';
        setMode(newMode);
        localStorage.setItem('app_mode', newMode);
    };

    return (
        <ModeContext.Provider value={{ mode, toggleMode }}>
            {children}
        </ModeContext.Provider>
    );
};

export const useMode = () => useContext(ModeContext);
