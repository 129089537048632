// src/pages/MyBookings.js

import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Box, List, Divider, ListItemIcon, CircularProgress, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { getUserTransactions } from '../services/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useAuth } from '../context/AuthContext';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const StyledListItem = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
}));

const ListingImage = styled('img')({
    width: '120px',
    height: '120px',
    objectFit: 'cover',
    borderRadius: '8px',
    marginRight: '16px',
});

const OwnerAvatar = styled(Avatar)({
    width: '32px',
    height: '32px',
    fontSize: '14px',
    marginRight: '8px',
});

const FilterModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const FilterPaper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    width: '300px',
}));

function MyBookings() {
    const { currentUser, loading: authLoading } = useAuth();
    const [bookings, setBookings] = useState([]);
    const [sortBy, setSortBy] = useState('rentalPeriodStart');
    const [tempSortBy, setTempSortBy] = useState('rentalPeriodStart');
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [tempSearchTerm, setTempSearchTerm] = useState('');
    const [loading, setLoading] = useState(true); // Local loading state for bookings

    useEffect(() => {
        if (!authLoading && currentUser) {
            fetchBookings();
        }
    }, [authLoading, currentUser]);

    const fetchBookings = async () => {
        try {
            const response = await getUserTransactions();
            // Filter bookings where current user is the borrower and the status is current (active)
            const borrowerBookings = response.data.filter(booking =>
                booking.borrower_id === currentUser.id &&
                ['pending', 'accepted', 'paid', 'in_progress'].includes(booking.status)
            );
            setBookings(borrowerBookings || []);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        } finally {
            setLoading(false);
        }
    };

    const getOwnerInitials = (name) => {
        if (!name) return '';
        const nameParts = name.split(' ');
        return `${nameParts[0]} ${nameParts[1] ? nameParts[1][0] + '.' : ''}`.trim();
    };

    const handleOpenFilterModal = () => {
        setTempSortBy(sortBy);
        setTempSearchTerm(searchTerm);
        setFilterModalOpen(true);
    };

    const handleCloseFilterModal = () => {
        setFilterModalOpen(false);
    };

    const handleUpdateFilter = () => {
        setSortBy(tempSortBy);
        setSearchTerm(tempSearchTerm);
        setFilterModalOpen(false);
    };

    const sortedAndFilteredBookings = bookings
        .filter(booking =>
            (booking.listing_title || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
            (booking.listing_owner_name || '').toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (sortBy === 'lastActivity') {
                return new Date(b.updated_at || 0) - new Date(a.updated_at || 0);
            } else if (sortBy === 'rentalPeriodStart') {
                return new Date(a.start_date || 0) - new Date(b.start_date || 0);
            } else if (sortBy === 'rentalPeriodEnd') {
                return new Date(a.end_date || 0) - new Date(b.end_date || 0);
            }
            return 0;
        });

    if (authLoading || loading) {
        return (
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (!currentUser) {
        return (
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>My Bookings</Typography>
                <Typography variant="body1">
                    You need to be logged in to view your bookings.
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Box display="flex" alignItems="center" mb={2}>
                <IconButton component={RouterLink} to="/bookings" edge="start" sx={{ mr: 2 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" component="h1" flexGrow={1}>
                    My Current Bookings
                </Typography>
                <IconButton onClick={handleOpenFilterModal}>
                    <FilterListIcon />
                </IconButton>
            </Box>

            <List>
                {sortedAndFilteredBookings.map((booking) => (
                    <React.Fragment key={booking.id}>
                        <StyledListItem
                            component={RouterLink}
                            to={`/transactions/${booking.id}`}
                            sx={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <ListingImage src={booking.listing_image} alt={booking.listing_title} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Box display="flex" alignItems="center" mb={1}>
                                    <OwnerAvatar src={booking.listing_owner_profile_picture} alt={booking.listing_owner_name}>
                                        {booking.listing_owner_name ? booking.listing_owner_name[0].toUpperCase() : 'U'}
                                    </OwnerAvatar>
                                    <Typography variant="subtitle1" component="span">
                                        {getOwnerInitials(booking.listing_owner_name)}
                                    </Typography>
                                </Box>
                                <ListItemText
                                    primary={booking.listing_title || 'Unnamed Item'}
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2" color="text.primary">
                                                {booking.status === 'pending' ? 'Your request has been sent' : `You are chatting with ${getOwnerInitials(booking.listing_owner_name)}`}
                                            </Typography>
                                            <br />
                                            {`Rental period: ${new Date(booking.start_date).toLocaleDateString()} - ${new Date(booking.end_date).toLocaleDateString()}`}
                                            <br />
                                            {`Last activity: ${new Date(booking.updated_at).toLocaleDateString()}`}
                                            <br />
                                            {`Amount: $${booking.transaction_amount.toFixed(2)}`}
                                        </>
                                    }
                                />
                            </Box>
                            <ChevronRightIcon color="action" sx={{ alignSelf: 'center' }} />
                        </StyledListItem>
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                ))}
            </List>
            {sortedAndFilteredBookings.length === 0 && <Typography>No current bookings found.</Typography>}

            <FilterModal
                open={filterModalOpen}
                onClose={handleCloseFilterModal}
            >
                <FilterPaper>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Filter
                    </Typography>
                    <TextField
                        fullWidth
                        label="Search by item or owner name"
                        value={tempSearchTerm}
                        onChange={(e) => setTempSearchTerm(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Typography variant="subtitle1" gutterBottom>
                        What do you want to sort by?
                    </Typography>
                    <RadioGroup
                        value={tempSortBy}
                        onChange={(e) => setTempSortBy(e.target.value)}
                    >
                        <FormControlLabel value="lastActivity" control={<Radio />} label="Last activity" />
                        <FormControlLabel value="rentalPeriodStart" control={<Radio />} label="Rental period start" />
                        <FormControlLabel value="rentalPeriodEnd" control={<Radio />} label="Rental period end" />
                    </RadioGroup>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleUpdateFilter}
                        sx={{ mt: 2 }}
                    >
                        Update
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleCloseFilterModal}
                        sx={{ mt: 1 }}
                    >
                        Cancel
                    </Button>
                </FilterPaper>
            </FilterModal>
        </Container>
    );
}

export default MyBookings;