import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Container, Typography, Paper, Grid, CircularProgress, Box, TextField, Button, Avatar,
    IconButton, useMediaQuery, useTheme, Rating, Card, CardContent, Snackbar, Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    getTransaction, getConversationByTransactionId, getConversationMessages, sendMessage,
    getUserBasicInfo, updateTransactionStatus
} from '../services/api';
import { useAuth } from '../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageIcon from '@mui/icons-material/Image';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { format } from 'date-fns';

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)',
}));

const ProgressBar = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    scrollbarWidth: 'none',
}));

const ProgressStep = styled(Box)(({ theme, active, completed }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '0 0 auto',
    padding: theme.spacing(0, 1),
    '& .MuiSvgIcon-root': {
        color: completed ? theme.palette.primary.main : (active ? theme.palette.primary.main : theme.palette.grey[300]),
        fontSize: '1.2rem',
    },
    '& .MuiTypography-root': {
        color: active ? theme.palette.text.primary : theme.palette.text.secondary,
        fontSize: '0.65rem',
        marginTop: theme.spacing(0.5),
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
}));

const ProgressLine = styled(Box)(({ theme, completed }) => ({
    height: 1,
    width: '100%',
    backgroundColor: completed ? theme.palette.primary.main : theme.palette.grey[300],
    flex: '1 1 auto',
}));

const MessageBubble = styled(Box)(({ theme, isCurrentUser }) => ({
    backgroundColor: isCurrentUser ? theme.palette.primary.main : theme.palette.grey[100],
    color: isCurrentUser ? theme.palette.common.white : theme.palette.text.primary,
    padding: theme.spacing(1, 2),
    borderRadius: 20,
    maxWidth: '70%',
    marginBottom: theme.spacing(1),
    alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
}));

const BookingInfoCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)',
}));

const BookingInfoItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
}));

const ItemCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
}));

const OwnerInfoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

function TransactionDetails() {
    const { currentUser, loading: authLoading } = useAuth();
    const [transaction, setTransaction] = useState(null);
    const [conversation, setConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newMessage, setNewMessage] = useState('');
    const [otherUserInfo, setOtherUserInfo] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const messagesEndRef = useRef(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const lastActivityTime = useRef(Date.now());
    const isActive = useRef(true);
    const pollingInterval = useRef(null);

    const updateLastActivity = useCallback(() => {
        lastActivityTime.current = Date.now();
        isActive.current = true;
    }, []);

    useEffect(() => {
        if (!authLoading && currentUser) {
            fetchTransactionAndConversation();
        }

        window.addEventListener('mousemove', updateLastActivity);
        window.addEventListener('keydown', updateLastActivity);
        window.addEventListener('click', updateLastActivity);
        window.addEventListener('scroll', updateLastActivity);

        return () => {
            window.removeEventListener('mousemove', updateLastActivity);
            window.removeEventListener('keydown', updateLastActivity);
            window.removeEventListener('click', updateLastActivity);
            window.removeEventListener('scroll', updateLastActivity);

            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
            }
        };
    }, [authLoading, currentUser, id, updateLastActivity]);

    useEffect(() => {
        if (conversation) {
            startPolling();
        }

        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
            }
        };
    }, [conversation]);

    const startPolling = () => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
        }

        pollingInterval.current = setInterval(() => {
            if (isActive.current && Date.now() - lastActivityTime.current <= 10000) {
                fetchMessages(conversation.id, true);
            } else {
                isActive.current = false;
            }
        }, 10000);
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const fetchTransactionAndConversation = async () => {
        try {
            setLoading(true);
            const transactionResponse = await getTransaction(id);
            setTransaction(transactionResponse.data);

            const otherUserId = currentUser.id === transactionResponse.data.borrower_id
                ? transactionResponse.data.lender_id
                : transactionResponse.data.borrower_id;

            if (otherUserId) {
                const userResponse = await getUserBasicInfo(otherUserId);
                setOtherUserInfo(userResponse.data);
            }

            const conversationResponse = await getConversationByTransactionId(id);
            if (conversationResponse.data && conversationResponse.data.length > 0) {
                setConversation(conversationResponse.data[0]);
                await fetchMessages(conversationResponse.data[0].id);
            }
        } catch (err) {
            console.error('Error fetching transaction and conversation:', err);
            setSnackbar({ open: true, message: 'Error fetching transaction data', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const fetchMessages = async (conversationId, isPolling = false) => {
        try {
            const messagesResponse = await getConversationMessages(conversationId);
            const newMessages = messagesResponse.data;
            if (isPolling && newMessages.length > messages.length) {
                setSnackbar({ open: true, message: 'New message received', severity: 'info' });
            }
            setMessages(newMessages);
        } catch (err) {
            console.error('Error fetching messages:', err);
        }
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim() || !conversation) return;

        try {
            await sendMessage({
                conversation_id: conversation.id,
                content: newMessage,
                borrower_id: conversation.borrower_id,
                lender_id: conversation.lender_id,
                sender_id: currentUser.id,
            });
            setNewMessage('');
            await fetchMessages(conversation.id);
            updateLastActivity();
        } catch (err) {
            console.error('Error sending message:', err);
            setSnackbar({ open: true, message: 'Error sending message', severity: 'error' });
        }
    };

    const handleUpdateStatus = async (status) => {
        try {
            await updateTransactionStatus(id, { status });
            setSnackbar({ open: true, message: `Transaction ${status}`, severity: 'success' });
            fetchTransactionAndConversation();
        } catch (err) {
            console.error(`Error updating transaction status to ${status}:`, err);
            setSnackbar({ open: true, message: 'Error updating status', severity: 'error' });
        }
    };

    const getStepStatus = () => {
        const steps = [
            { label: 'Pending', status: 'pending' },
            { label: 'Accepted', status: 'accepted' },
            { label: 'Paid', status: 'paid' },
            { label: 'In Progress', status: 'in_progress' },
            { label: 'Completed', status: 'completed' },
        ];
        const currentIndex = steps.findIndex(step => step.status === transaction?.status);
        return steps.map((step, index) => ({
            label: step.label,
            completed: index <= currentIndex,
            active: index === currentIndex,
        }));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "EEE, MMM d, yyyy");
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleItemClick = () => {
        navigate(`/listings/${transaction?.listing_id}`);
    };

    const renderActionButtons = () => {
        const status = transaction?.status;
        if (currentUser.id === transaction?.lender_id) {
            // Actions for Lender
            switch (status) {
                case 'pending':
                    return (
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleUpdateStatus('accepted')}
                            >
                                Accept Request
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                onClick={() => handleUpdateStatus('declined')}
                                sx={{ mt: 1 }}
                            >
                                Decline Request
                            </Button>
                        </Box>
                    );
                case 'accepted':
                    return (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            Waiting for borrower to make payment.
                        </Typography>
                    );
                case 'paid':
                    return (
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleUpdateStatus('in_progress')}
                            >
                                Start Transaction
                            </Button>
                        </Box>
                    );
                case 'in_progress':
                    return (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            Transaction is in progress.
                        </Typography>
                    );
                case 'completed':
                    return (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            Transaction completed.
                        </Typography>
                    );
                case 'declined':
                case 'canceled':
                case 'failed':
                    return (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            This booking has been {status}.
                        </Typography>
                    );
                default:
                    return null;
            }
        } else if (currentUser.id === transaction?.borrower_id) {
            // Actions for Borrower
            switch (status) {
                case 'pending':
                    return (
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() => handleUpdateStatus('canceled')}
                            >
                                Cancel Request
                            </Button>
                        </Box>
                    );
                case 'accepted':
                    return (
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleUpdateStatus('paid')}
                            >
                                Pay Now
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                onClick={() => handleUpdateStatus('canceled')}
                                sx={{ mt: 1 }}
                            >
                                Cancel Booking
                            </Button>
                        </Box>
                    );
                case 'paid':
                    return (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            Waiting for lender to start the transaction.
                        </Typography>
                    );
                case 'in_progress':
                    return (
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleUpdateStatus('completed')}
                            >
                                Mark as Completed
                            </Button>
                        </Box>
                    );
                case 'completed':
                    return (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            Transaction completed.
                        </Typography>
                    );
                case 'declined':
                case 'canceled':
                case 'failed':
                    return (
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                            This booking has been {status}.
                        </Typography>
                    );
                default:
                    return null;
            }
        }
    };

    if (authLoading || loading) {
        return (
            <StyledContainer maxWidth="md">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <CircularProgress />
                </Box>
            </StyledContainer>
        );
    }

    if (!currentUser) {
        return (
            <StyledContainer maxWidth="md">
                <Typography variant="h5" component="h1">
                    Booking Request Details
                </Typography>
                <Typography variant="body1">
                    You need to be logged in to view booking details.
                </Typography>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer maxWidth="md">
            <Box display="flex" alignItems="center" mb={3}>
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5" component="h1">
                    Booking Request Details
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                    <StyledPaper>
                        <ProgressBar>
                            {getStepStatus().map((step, index) => (
                                <React.Fragment key={index}>
                                    <ProgressStep active={step.active} completed={step.completed}>
                                        {step.completed ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                                        <Typography>{step.label}</Typography>
                                    </ProgressStep>
                                    {index < getStepStatus().length - 1 && (
                                        <ProgressLine completed={getStepStatus()[index + 1].completed} />
                                    )}
                                </React.Fragment>
                            ))}
                        </ProgressBar>
                        <Typography variant="h6" gutterBottom>
                            {transaction?.status === 'pending' ? 'New booking request received' : `Booking ${transaction?.status}`}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {transaction?.status === 'pending'
                                ? 'Please respond to this request promptly.'
                                : `Last updated: ${format(new Date(transaction?.updated_at), "MMM d, yyyy HH:mm")}`}
                        </Typography>

                        <ItemCard onClick={handleItemClick}>
                            <CardContent>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <Avatar variant="rounded" src={transaction?.listing_image} alt={transaction?.listing_title}>
                                            <ImageIcon />
                                        </Avatar>
                                        <Typography variant="subtitle1" ml={2}>
                                            {transaction?.listing_title}
                                        </Typography>
                                    </Box>
                                    <ChevronRightIcon color="action" />
                                </Box>
                            </CardContent>
                        </ItemCard>

                        <OwnerInfoBox>
                            <Avatar src={otherUserInfo?.profile_picture} alt={otherUserInfo?.first_name} sx={{ width: 64, height: 64, mr: 2 }}>
                                {otherUserInfo?.first_name?.[0]}
                            </Avatar>
                            <Box>
                                <Typography variant="subtitle1">
                                    {otherUserInfo?.first_name} {otherUserInfo?.last_name}
                                </Typography>
                                <Rating value={otherUserInfo?.average_rating || 0} readOnly size="small" />
                                <Box display="flex" alignItems="center" mt={0.5}>
                                    <LocationOnIcon fontSize="small" color="action" />
                                    <Typography variant="body2" color="textSecondary" ml={0.5}>
                                        {otherUserInfo?.zip_code}
                                    </Typography>
                                </Box>
                            </Box>
                        </OwnerInfoBox>

                        <BookingInfoCard>
                            <CardContent>
                                <BookingInfoItem>
                                    <CalendarTodayIcon />
                                    <Box>
                                        <Typography variant="subtitle2">Rental period</Typography>
                                        <Typography variant="body2">
                                            {formatDate(transaction?.start_date)} - {formatDate(transaction?.end_date)}
                                        </Typography>
                                    </Box>
                                </BookingInfoItem>
                                <BookingInfoItem>
                                    <ConfirmationNumberIcon />
                                    <Box>
                                        <Typography variant="subtitle2">Booking number</Typography>
                                        <Typography variant="body2">{transaction?.id}</Typography>
                                    </Box>
                                </BookingInfoItem>
                                <BookingInfoItem>
                                    <AttachMoneyIcon />
                                    <Box>
                                        <Typography variant="subtitle2">Total price</Typography>
                                        <Typography variant="body2">
                                            ${transaction?.transaction_amount}
                                            <Typography variant="caption" color="textSecondary" component="span" ml={1}>
                                                (incl. booking fee)
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </BookingInfoItem>
                            </CardContent>
                        </BookingInfoCard>

                        {renderActionButtons()}
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={5}>
                    <StyledPaper>
                        <Typography variant="h6" gutterBottom>
                            Messages
                        </Typography>
                        <Box mt={2} mb={2} sx={{ height: isMobile ? '300px' : '400px', overflowY: 'auto' }}>
                            {messages.map((message, index) => (
                                <Box key={index} mb={2}>
                                    <Typography variant="caption" color="textSecondary">
                                        {format(new Date(message.created_at), "MMM d, yyyy HH:mm")}
                                    </Typography>
                                    <MessageBubble isCurrentUser={message.sender_id === currentUser.id}>
                                        <Typography variant="body2">{message.content}</Typography>
                                    </MessageBubble>
                                </Box>
                            ))}
                            <div ref={messagesEndRef} />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder={`Message to ${otherUserInfo?.first_name}...`}
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                size="small"
                                sx={{ mr: 1 }}
                            />
                            <IconButton onClick={handleSendMessage} color="primary">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" fill="currentColor" />
                                </svg>
                            </IconButton>
                        </Box>
                    </StyledPaper>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </StyledContainer>
    );
}

export default TransactionDetails;
