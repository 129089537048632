import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container, Typography, Paper, TextField, Button, Grid, Snackbar, CircularProgress,
    IconButton, Box, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText,
    DialogActions, Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { createListing, uploadListingImages, createBlockedPeriod, getTopLevelCategories, getSubcategories } from '../services/api';
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/colors/green.css";
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const ImagePreview = styled('img')({
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    margin: '5px',
    borderRadius: '4px',
});

const ImagePreviewContainer = styled(Box)({
    position: 'relative',
    display: 'inline-block',
});

const DeleteButton = styled(IconButton)({
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
});

const CalendarWrapper = styled(Box)({
    '& .rmdp-wrapper': {
        fontFamily: 'Filson Soft, Arial, sans-serif',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
        borderRadius: '8px',
    },
    '& .rmdp-header': {
        backgroundColor: '#ffffff',
        borderBottom: 'none',
        padding: '0.5rem 0',
    },
    '& .rmdp-day.selected': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .rmdp-day.range': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .rmdp-day.in-range:hover': {
        backgroundColor: '#45a049',
        color: 'white',
    },
    '& .rmdp-day.today': {
        border: '1px solid #4CAF50',
    },
    '& .rmdp-week-day': {
        color: '#4CAF50',
    },
    '& .rmdp-day.disabled': {
        color: '#ccc',
        pointerEvents: 'none',
        opacity: 0.5,
    },
    '& .rmdp-day.outside': {
        color: '#000',
        opacity: 1,
    },
    '& .rmdp-panel-body li .b-date': {
        backgroundColor: '#4CAF50',
        border: 'none',
    },
    '& .rmdp-panel-body li button': {
        color: '#fff',
        cursor: 'pointer',
    },
    '& .rmdp-panel-body li .b-deselect': {
        backgroundColor: '#4CAF50',
        border: 'none',
        fontSize: '17px',
    },
    '& .rmdp-date-panel': {
        fontFamily: 'Filson Soft, Arial, sans-serif',
        backgroundColor: '#000',
        borderRadius: '8px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
    },
    '& .rmdp-date': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .rmdp-range': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
    '& .rmdp-clear-button': {
        backgroundColor: '#4CAF50',
        color: 'white',
    },
});

const LocationChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const MapCard = styled(Paper)(({ theme }) => ({
    height: '300px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
}));

function LocationPicker({ onLocationChange, locations }) {
    const map = useMapEvents({
        click(e) {
            if (locations.length < 3) {
                onLocationChange(e.latlng);
            }
        },
    });
    return null;
}

function CreateListing() {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [images, setImages] = useState([]);
    const [blockedPeriods, setBlockedPeriods] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(true);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categoryPath, setCategoryPath] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        fetchTopLevelCategories();
    }, []);

    const fetchTopLevelCategories = async () => {
        try {
            const response = await getTopLevelCategories();
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching top-level categories:', error);
            setSnackbar({ open: true, message: 'Failed to load categories. Please try again.' });
        }
    };

    const handleCategorySelect = async (category) => {
        try {
            const response = await getSubcategories(category.id);
            if (response.data.length > 0) {
                setCategories(response.data);
                setCategoryPath([...categoryPath, category]);
            } else {
                setSelectedCategory(category);
                setCategoryDialogOpen(false);
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
            setSnackbar({ open: true, message: 'Failed to load subcategories. Please try again.' });
        }
    };

    const handleCategoryBack = async () => {
        if (categoryPath.length > 1) {
            const newPath = [...categoryPath];
            newPath.pop();
            setCategoryPath(newPath);
            const parentCategory = newPath[newPath.length - 1];
            const response = await getSubcategories(parentCategory.id);
            setCategories(response.data);
        } else {
            fetchTopLevelCategories();
        }
    };

    const handleLocationChange = (newLocation) => {
        if (locations.length < 3) {
            setLocations([...locations, { latitude: newLocation.lat, longitude: newLocation.lng }]);
        }
    };

    const handleLocationDelete = (index) => {
        setLocations(locations.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedCategory) {
            setSnackbar({ open: true, message: 'Please select a category before submitting.' });
            return;
        }
        setLoading(true);

        try {
            const listingData = {
                title,
                description,
                price: parseFloat(price),
                category_id: selectedCategory.id,
                locations: locations,
            };
            const response = await createListing(listingData);
            const newListingId = response.data.id;

            if (images.length > 0) {
                const formData = new FormData();
                images.forEach((file) => formData.append('files', file));
                await uploadListingImages(newListingId, formData);
            }

            for (const [start, end] of blockedPeriods) {
                await createBlockedPeriod(newListingId, {
                    start_date: start.toISOString(),
                    end_date: end.toISOString()
                });
            }

            setSnackbar({ open: true, message: 'Listing created successfully!' });
            setTimeout(() => navigate(`/my-listings/${newListingId}`), 2000);
        } catch (error) {
            console.error('Error creating listing:', error);
            setSnackbar({ open: true, message: 'Failed to create listing. Please try again.' });
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (e) => {
        const newImages = Array.from(e.target.files);
        setImages([...images, ...newImages]);
    };

    const handleImageDelete = (index) => {
        setImages(images.filter((_, i) => i !== index));
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Container maxWidth="md">
            <StyledPaper elevation={3}>
                <Typography variant="h4" gutterBottom>
                    Create New Listing
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Category"
                                value={selectedCategory ? selectedCategory.name : ''}
                                onClick={() => setCategoryDialogOpen(true)}
                                InputProps={{ readOnly: true }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Price per day"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={handleImageChange}
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="contained" component="span">
                                    Upload Images
                                </Button>
                            </label>
                            <Box mt={2}>
                                {images.map((image, index) => (
                                    <ImagePreviewContainer key={index}>
                                        <ImagePreview src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
                                        <DeleteButton size="small" onClick={() => handleImageDelete(index)}>
                                            <CloseIcon fontSize="small" />
                                        </DeleteButton>
                                    </ImagePreviewContainer>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                                Blocked Periods
                            </Typography>
                            <CalendarWrapper>
                                <Calendar
                                    value={blockedPeriods}
                                    onChange={setBlockedPeriods}
                                    multiple
                                    range
                                    plugins={[
                                        <DatePanel position="bottom" header='Unavailable Dates' />
                                    ]}
                                    minDate={new Date()}
                                    autoFocus={false}
                                    highlightToday={false}
                                    onOpenPickNewDate={false}
                                />
                            </CalendarWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                                Item Locations (up to 3)
                            </Typography>
                            <MapCard>
                                <MapContainer
                                    center={[40.7128, -74.0060]}
                                    zoom={13}
                                    style={{ height: '100%', width: '100%' }}
                                >
                                    <TileLayer
                                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                    // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    {locations.map((location, index) => (
                                        <Marker key={index} position={[location.latitude, location.longitude]} />
                                    ))}
                                    <LocationPicker onLocationChange={handleLocationChange} locations={locations} />
                                </MapContainer>
                            </MapCard>
                            <Box mt={1}>
                                {locations.map((location, index) => (
                                    <LocationChip
                                        key={index}
                                        icon={<LocationOnIcon />}
                                        label={`Location ${index + 1}`}
                                        onDelete={() => handleLocationDelete(index)}
                                    />
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading || !selectedCategory}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Create Listing'}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </form>
            </StyledPaper>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
            />
            <Dialog open={categoryDialogOpen} onClose={() => setCategoryDialogOpen(false)}>
                <DialogTitle>
                    {categoryPath.length > 0 && (
                        <IconButton edge="start" color="inherit" onClick={handleCategoryBack} aria-label="back">
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    Select category
                </DialogTitle>
                <DialogContent>
                    <List>
                        {categories.map((category) => (
                            <ListItem button key={category.id} onClick={() => handleCategorySelect(category)}>
                                <ListItemText primary={category.name} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCategoryDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default CreateListing;