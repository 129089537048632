import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Grid, Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar, CircularProgress, TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getConversations, getConversationMessages, sendMessage, markMessageAsRead } from '../services/api';
import { useAuth } from '../context/AuthContext';

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    height: '70vh',
    overflow: 'hidden',
}));

const MessageList = styled('div')(({ theme }) => ({
    height: 'calc(100% - 100px)',
    overflowY: 'auto',
    padding: theme.spacing(2),
}));

const MessageBubble = styled('div')(({ theme, isCurrentUser }) => ({
    backgroundColor: isCurrentUser ? theme.palette.primary.main : theme.palette.grey[300],
    color: isCurrentUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
    padding: theme.spacing(1, 2),
    borderRadius: 20,
    maxWidth: '70%',
    marginBottom: theme.spacing(1),
    alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
}));

const MessageInputContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
}));

function Inbox() {
    const { currentUser } = useAuth();
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchConversations = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getConversations();
            setConversations(response.data);
        } catch (err) {
            console.error('Error fetching conversations:', err);
            setError('Failed to load conversations. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchConversations();
    }, [fetchConversations]);

    const fetchMessages = useCallback(async (conversationId) => {
        try {
            setLoading(true);
            const response = await getConversationMessages(conversationId);
            setMessages(response.data);
            // Mark all messages as read
            response.data.forEach(message => {
                if (!message.is_read) {
                    markMessageAsRead(message.id);
                }
            });
        } catch (err) {
            console.error('Error fetching messages:', err);
            setError('Failed to load messages. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, []);

    const handleConversationSelect = (conversation) => {
        setSelectedConversation(conversation);
        fetchMessages(conversation.id);
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !selectedConversation) return;

        try {
            const messageData = {
                conversation_id: selectedConversation.id,
                content: newMessage,
                borrower_id: selectedConversation.borrower_id,
                lender_id: selectedConversation.lender_id
            };
            const response = await sendMessage(messageData);
            setMessages(prevMessages => [...prevMessages, response.data]);
            setNewMessage('');
        } catch (err) {
            console.error('Error sending message:', err);
            setError('Failed to send message. Please try again.');
        }
    };

    if (loading && conversations.length === 0) {
        return (
            <StyledContainer>
                <CircularProgress />
            </StyledContainer>
        );
    }

    if (error) {
        return (
            <StyledContainer>
                <Typography color="error">{error}</Typography>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer>
            <Typography variant="h4" component="h1" gutterBottom>
                Inbox
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <StyledPaper>
                        <List>
                            {conversations.filter(c => c.borrower_id === currentUser.id).map((conversation) => (
                                <ListItem
                                    button
                                    key={conversation.id}
                                    onClick={() => handleConversationSelect(conversation)}
                                    selected={selectedConversation?.id === conversation.id}
                                >
                                    <ListItemAvatar>
                                        <Avatar>{conversation.borrower_first_name[0]}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${conversation.borrower_first_name} ${conversation.borrower_last_name}`}
                                        secondary={`Last message: ${new Date(conversation.last_message_at).toLocaleString()}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </StyledPaper>
                    <StyledPaper>
                        <List>
                            {conversations.filter(c => c.lender_id === currentUser.id).map((conversation) => (
                                <ListItem
                                    button
                                    key={conversation.id}
                                    onClick={() => handleConversationSelect(conversation)}
                                    selected={selectedConversation?.id === conversation.id}
                                >
                                    <ListItemAvatar>
                                        <Avatar>{conversation.borrower_first_name[0]}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${conversation.borrower_first_name} ${conversation.borrower_last_name}`}
                                        secondary={`Last message: ${new Date(conversation.last_message_at).toLocaleString()}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={8}>
                    <StyledPaper>
                        {selectedConversation ? (
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Chat with {selectedConversation.borrower_first_name} {selectedConversation.borrower_last_name}
                                </Typography>
                                <MessageList>
                                    {messages.map((message) => (
                                        <MessageBubble
                                            key={message.id}
                                            isCurrentUser={message.sender_id === selectedConversation.lender_id}
                                        >
                                            <Typography variant="body2">{message.content}</Typography>
                                        </MessageBubble>
                                    ))}
                                </MessageList>
                                <MessageInputContainer>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Type a message..."
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSendMessage}
                                        style={{ marginLeft: '8px' }}
                                    >
                                        Send
                                    </Button>
                                </MessageInputContainer>
                            </>
                        ) : (
                            <Typography variant="body1" align="center">
                                Select a conversation to start chatting
                            </Typography>
                        )}
                    </StyledPaper>
                </Grid>
            </Grid>
        </StyledContainer>
    );
}

export default Inbox;